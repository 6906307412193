import {
  updateCollection,
  deleteCollection,
  shareCollection,
  createCollection,
} from "./collection";
import { trackForm } from "./form";
import { getDigitalSchedule } from "./schedule";
import { getUserData, storeUserSeach } from "./user";

export const Api = {
  updateCollection,
  deleteCollection,
  shareCollection,
  createCollection,
  trackForm,
  getDigitalSchedule,
  getUserData,
  storeUserSeach,
};
