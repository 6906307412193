import { PAGINATION_FRAGMENT } from "@/services/providers/gemini/queries/shared_fragments/pagination_fragment";
import { gql } from "graphql-request";

export const CAMPAIGN_FRAGMENT = gql`
  fragment CampaignFragment on Campaign {
    id
    title
    year
    slug
    tags
    awardCount
    shortlisted
    assets {
      id
      type
      thumbnailUrl
      label
      fullUrl
      access
      downloadable
    }
    credits {
      people {
        name
        slug
        company
        role
      }
      companies {
        name
        slug
        location
        role
      }
    }
    brand {
      name
      slug
    }
    company {
      name
      town
    }
    caseStudy {
      background
      description
      execution
      idea
      outcome
      strategy
    }
    awards {
      count
      level
      levelLabel
    }
    festival {
      code
      name
    }
    entryTypes {
      entries {
        category
        level
        levelLabel
        section
        slug
        title
      }
      name
      slug
    }
    access
    saveable
    downloadable
    requiredUserActions
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
  query ($id: Int!) {
    getCampaignsByIds(ids: [$id]) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;

export const GET_FILTERED_CAMPAIGNS = gql`
  query (
    $award_levels: [String]
    $countries: [String]
    $entered_lions: [String]
    $festival_name: [String]
    $ids: [Int]
    $media_categories: [String]
    $sectors: [String]
    $search_text: String
    $sort: String
    $years: [Int]
    $page: Int
  ) {
    filteredCampaigns(
      filters: {
        awards: $award_levels
        countries: $countries
        enteredLions: $entered_lions
        festivalName: $festival_name
        ids: $ids
        mediaCategories: $media_categories
        sectors: $sectors
        searchText: $search_text
        sort: $sort
        years: $years
        page: $page
      }
    ) {
      campaigns {
        ...CampaignFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CAMPAIGN_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_RELATED_CAMPAIGNS = gql`
  query ($campaignId: Int) {
    getRelatedCampaigns(campaignId: $campaignId) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;
