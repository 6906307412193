import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_USER_ATTRIBUTES } from "../queries/user_attributes_query";
import { UserAttributes } from "../types";

export const getUserAttributes = async (
  userId: string,
  accessToken?: string
): Promise<UserAttributes> => {
  const response = await callGemini(
    { query: GET_USER_ATTRIBUTES, variables: { userId } },
    accessToken
  );

  const { getAttributesForUser: attributes } = response;
  return attributes;
};
