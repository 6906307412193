import { gql } from "graphql-request";

export const GET_USER_ATTRIBUTES = gql`
  query GetUserAttributes($userId: String!) {
    getAttributesForUser(userId: $userId) {
      currentDownloads
      totalAvailableDownloads
    }
  }
`;
