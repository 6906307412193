/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect } from "react";
import { EventEmitter } from "eventemitter3";

const emitter = new EventEmitter();

export interface DownloadLimitNearEventPayload {
  currentDownloads: number;
  totalAvailableDownloads: number;
}

export const useSub = (event: string, callback: any) => {
  const unsubscribe = useCallback(() => {
    emitter.off(event, callback);
  }, [callback, event]);

  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, [callback, event, unsubscribe]);

  return unsubscribe;
};

export const usePub = () => {
  return (event: string, data: object) => {
    emitter.emit(event, data);
  };
};

export const publishDownloadLimitReached = () => {
  const publish = usePub();

  publish("downloadLimitReached", {});
};

export const subscribeDownloadLimitReached = (handler: Function) => {
  useSub("downloadLimitReached", () => handler());
};

export const publishDownloadLimitNear = ({
  currentDownloads,
  totalAvailableDownloads,
}: DownloadLimitNearEventPayload) => {
  const publish = usePub();

  publish("downloadLimitNear", {
    currentDownloads,
    totalAvailableDownloads,
  });
};

export const subscribeDownloadLimitNear = (handler: Function) => {
  useSub("downloadLimitNear", (payload: DownloadLimitNearEventPayload) =>
    handler(payload)
  );
};
