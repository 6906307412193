import { gql } from "graphql-request";

export const GET_SEARCH_COUNTS = gql`
  query getSearchCounts($searchText: String) {
    getSearchCounts(filters: { search_text: $searchText }) {
      campaignCount
      editorialCount
      inspiraionCount
      talkCount
      totalCount
    }
  }
`;

export const SAVE_SEARCH = gql`
  mutation CreateSearchHistory(
    $searchText: String!
    $searchParamaters: String!
    $contentType: String!
  ) {
    CreateSearchHistory(
      searchText: $searchText
      searchParamaters: $searchParamaters
      contentType: $contentType
    ) {
      userId
      searchText
      searchParamaters
      contentType
    }
  }
`;
