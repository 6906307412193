import { callClientApi, callGetApi } from "../client_api_caller";
import { currentSearchQuery } from "../QueryParams/query_params";

export const getUserData = async () => {
  const response = await callGetApi("/api/user");
  if (response.ok) {
    return response.json();
  }
};

export const storeUserSeach = async (searchText: string) => {
  const currentQuery = currentSearchQuery();
  const response = await callClientApi("/api/user/save-search", {
    searchText,
    currentQuery,
  });
};
