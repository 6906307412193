import { PAGINATION_FRAGMENT } from "@/services/providers/gemini/queries/shared_fragments/pagination_fragment";
import { gql } from "graphql-request";
const ENTRY_FRAGMENT = gql`
  fragment EntryFragment on Entry {
    id
    title
    year
    assets {
      id
      type
      thumbnailUrl
      label
      fullUrl
      access
      downloadable
    }
    credits {
      people {
        name
        slug
        company
        role
      }
      companies {
        name
        slug
        location
        role
      }
    }
    brand {
      name
      slug
    }
    campaign {
      slug
    }
    company {
      name
      town
    }
    entryType {
      name
      slug
      id
      category {
        name
        slug
        id
      }
      award {
        level
        levelLabel
      }
      section {
        name
      }
    }
    caseStudy {
      question
      response
    }
    festival {
      code
      name
    }
    slug
    tags
    access
    saveable
    requiredUserActions
  }
`;

export const GET_ENTRIES_BY_IDS = gql`
  query ($ids: [Int]!) {
    getEntriesByIds(ids: $ids) {
      ...EntryFragment
    }
  }
  ${ENTRY_FRAGMENT}
`;

export const GET_FILTERED_ENTRIES = gql`
  query filteredEntries(
    $award_levels: [String]
    $categories: [Int]
    $entry_type_id: [Int]
    $festival_name: [String]
    $ids: [Int]
    $years: [Int]
    $company_names: [String]
    $page: Int
  ) {
    filteredEntries(
      filters: {
        awards: $award_levels
        categoryIds: $categories
        entryTypeId: $entry_type_id
        festivalName: $festival_name
        ids: $ids
        years: $years
        companyNames: $company_names
        page: $page
      }
    ) {
      entries {
        ...EntryFragment
      }
      pagination {
        ...PaginationFragment
      }
      filterCounts {
        key
        label
        values {
          count
          key
          label
        }
      }
    }
  }
  ${ENTRY_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;
