import { Api } from "@/libs/api";
import {
  publishDownloadLimitReached,
  publishDownloadLimitNear,
} from "../pubSub";

export const checkDownloadCount = () => {
  Api.getUserData().then((data) => {
    const { currentDownloads, totalAvailableDownloads, warningLimit } = data;
    if (currentDownloads >= totalAvailableDownloads) {
      publishDownloadLimitReached();
      return true;
    }

    if (totalAvailableDownloads - warningLimit <= currentDownloads) {
      publishDownloadLimitNear({
        currentDownloads,
        totalAvailableDownloads,
      });
      return true;
    }
  });
};
