import { useState, Dispatch, SetStateAction } from "react";
import { GalleryCarousel, Image } from "@horizon/components";
import { LightboxComponent } from "@/components";
import { GalleryCarouselData } from "@/components/components.d";
import { MediaPlayerData, MediaContent, MediaType } from "./media.d";

import {
  USER_ACTIONS,
  UserAction,
  userHasAction,
} from "@/libs/authentication/user";
import { checkDownloadCount } from "@/libs/downloads";

export const GalleryCarouselComponent = ({
  media,
  userActions,
}: GalleryCarouselData) => {
  const [isOpen, setIsOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const isGalleryAvailable = media.length > 0;
  const slides = media.map((item, index) =>
    buildSlideObject(item, index, setIsOpen, setSlideIndex, userActions)
  );
  const mediaPlayerData = media.map((item) => buildMediaPlayerData(item));

  return (
    <>
      {isGalleryAvailable && (
        <>
          <GalleryCarousel slides={slides} />
          <LightboxComponent
            activeIndex={slideIndex}
            media={mediaPlayerData}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            shouldAutoplay={true}
            showDownloadCta={canUserDownloadMedia(
              media[slideIndex],
              userActions
            )}
          />
        </>
      )}
    </>
  );
};

export const buildMediaPlayerData = (
  mediaContent: MediaContent
): MediaPlayerData => ({
  media: mediaContent,
  type: "MediaPlayerData",
});

export const buildSlideObject = (
  mediaContent: MediaContent,
  index: number,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  setSlideIndex: Dispatch<SetStateAction<number>>,
  userActions?: UserAction[]
) => {
  const slideCallback = () => {
    setSlideIndex(index);
    setIsOpen(true);
  };
  const { id, mediaType, thumbnail, alt } = mediaContent;
  const component = <Image mediaType="image" src={thumbnail} alt={alt} />;
  const cta = id ? buildCta(mediaType, id, slideCallback) : undefined;

  const downloadCta = {
    accessibilityLabel: "Download the Carousel Item",
    href: cta?.href,
    icon: { name: "Download", position: "end" },
    variant: "secondary",
    children: "Download",
    onClick: () => checkDownloadCount(),
  };

  const downloadLimitCta = {
    accessibilityLabel: "Download Limit Reached",
    href: cta?.href,
    icon: { name: "Download", position: "end" },
    variant: "secondary",
    children: "Download limit reached",
    disabled: true,
    onClick: () => {},
  };

  const fullScreenCta = {
    accessibilityLabel: "Fullscreen the Carousel Item",
    href: "#",
    icon: { name: "Expand", position: "end" },
    variant: "secondary",
    children: "Full Screen",
    onClick: () => slideCallback(),
  };

  const ctas = [
    canUserDownloadMedia(mediaContent, userActions) && downloadCta,
    showDownloadLimitReached(mediaContent, userActions) && downloadLimitCta,
    fullScreenCta,
  ].filter(Boolean);
  return {
    component,
    cta,
    ctas,
  };
};

export interface IGalleryCarouselCta {
  href: string;
  icon: { name: string; color?: string };
  onClick: () => void;
}

const buildCta = (mediaType: MediaType, id: number, callback: () => void) => ({
  href: `/api/assets/download/${id}`,
  onClick: callback,
  icon: buildIcon(mediaType),
});

const buildIcon = (mediaType: MediaType) => {
  switch (mediaType) {
    case "video/mp4":
    case "audio/mp3":
      return { name: "PlayFull" };
    case "application/pdf":
      return { name: "Pdf" };
    case "image/jpeg":
    case "image/png":
    case "image/zoom":
      return { name: "ZoomIn" };
  }
};

const canUserDownloadMedia = (
  mediaContent: MediaContent,
  userActions?: UserAction[]
) =>
  userHasAction(USER_ACTIONS.ASSETS_DOWNLOAD, userActions) &&
  (mediaContent.downloadable ?? false);

const showDownloadLimitReached = (
  mediaContent: MediaContent,
  userActions?: UserAction[]
) =>
  userHasAction(USER_ACTIONS.ASSETS_DOWNLOAD, userActions) &&
  (!mediaContent.downloadable ?? false);
