import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { ComponentData, SpacerData, PageData } from "@/renderers";
import { Service, ServiceArgs } from "@/services";
import {
  PATHS,
  SITE_TITLE,
  buildContainerData,
  buildDarkSectionData,
  buildSectionData,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { ContentfulProvider, GeminiProvider } from "@/services/providers";
import {
  FilterOption,
  FilteredContent,
  SearchCounts,
} from "@/services/providers/gemini/types";
import {
  SearchMapper,
  TABS,
  SearchFilteredContent,
  AGGREGATE_TAB,
} from "@/services/search/search.mapper";
import { Authentication } from "@/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { accessToken, queryParams } = serviceArgs;
  const parsedQuery = QueryParams.parseSearchQuery(queryParams);

  const [filterOptions, filteredContent, searchCounts, searchSuggestions] =
    await Promise.all([
      getFilterOptions(parsedQuery, accessToken),
      getFilteredContent(parsedQuery, accessToken),
      getCounts(parsedQuery, accessToken),
      getSearchSuggestions(),
    ]);

  const result = {
    title: `Search | ${SITE_TITLE}`,
    metaDescription: "Search the award winning content of The Work.",
    slug: `${PATHS.search}`,
    cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.search}`,
    metaTitle: "The Work Search",
    components: await buildListingComponents(
      parsedQuery,
      searchSuggestions,
      serviceArgs,
      filteredContent,
      filterOptions,
      searchCounts
    ),
  };

  return stripUndefinedValuesFromObject(result);
};

const buildListingComponents = async (
  parsedQuery: ParsedQuery,
  searchSuggestions: string[],
  serviceArgs: ServiceArgs,
  filteredContent?: FilteredContent | FilteredContent[],
  filterOptions?: FilterOption[],
  searchCounts?: SearchCounts
): Promise<ComponentData[]> => {
  return [
    buildDarkSectionData(
      [
        SearchMapper.toSearchBarData(
          (parsedQuery?.search_text as string) || "",
          searchSuggestions
        ),
      ].filter(Boolean) as ComponentData[],
      "SEARCH THE WORK",
      "black"
    ),
    buildSectionData(
      [
        maybeAddTabContainer(
          parsedQuery,
          filteredContent,
          filterOptions,
          searchCounts
        ),
        buildContainerData([
          maybeAddNoResults(
            filteredContent,
            parsedQuery?.search_text as string
          ),
        ]),
        maybeCreatePaywall(serviceArgs, Authentication.USER_ACTIONS.CAN_SEARCH),
      ].filter(Boolean) as ComponentData[],
      "",
      "offwhite"
    ),
  ];
};

const maybeAddTabContainer = (
  parsedQuery: ParsedQuery,
  filteredContent: SearchFilteredContent,
  filterOptions?: FilterOption[],
  searchCounts?: SearchCounts
) => {
  return SearchMapper.toTabContainer(
    getTabIndexFromQuery(parsedQuery),
    filteredContent,
    parsedQuery,
    filterOptions,
    searchCounts
  );
};

const getTabIndexFromQuery = (
  parsedQuery: ParsedQuery
): { key: string; value: string } | undefined => {
  const content_type = parsedQuery?.content_type;
  if (content_type) {
    return { key: "content_type", value: content_type as string };
  }
  return undefined;
};

const getFilteredContent = async (
  parsedQuery: ParsedQuery,
  accessToken: string | undefined
): Promise<FilteredContent | FilteredContent[] | undefined> => {
  parsedQuery = setDefaultSort(parsedQuery);
  if (
    parsedQuery?.search_text &&
    parsedQuery?.content_type !== AGGREGATE_TAB.label
  ) {
    return await GeminiProvider.getFilteredContent(parsedQuery, accessToken);
  } else if (parsedQuery?.search_text) {
    return await Promise.all(
      Object.values(TABS).map((tab) =>
        GeminiProvider.getFilteredContent(
          replaceContentTypeInQuery(parsedQuery, tab.label),
          accessToken
        )
      )
    );
  }
  return undefined;
};

const getCounts = async (
  parsedQuery: ParsedQuery,
  accessToken: string | undefined
): Promise<SearchCounts> => {
  return GeminiProvider.getSearchCounts(parsedQuery, accessToken);
};

const getSearchSuggestions = async () => ContentfulProvider.getSearchMetaData();

const setDefaultSort = (parsedQuery: ParsedQuery): ParsedQuery => {
  const contentType = parsedQuery?.content_type;
  if (typeof contentType === "string" && contentType in TABS) {
    const tab = TABS[contentType as keyof typeof TABS];
    return tab.filter
      ? QueryParams.assureDefaultSort(parsedQuery, tab.filter[0].value)
      : parsedQuery;
  } else {
    return parsedQuery;
  }
};

const replaceContentTypeInQuery = (
  parsedQuery: ParsedQuery,
  contentType: string
) => {
  return { ...parsedQuery, content_type: contentType };
};

const getFilterOptions = async (
  parsedQuery: ParsedQuery,
  accessToken: string | undefined
): Promise<FilterOption[] | undefined> => {
  if (
    parsedQuery?.search_text &&
    parsedQuery?.content_type === TABS.campaigns.label
  ) {
    return await GeminiProvider.getCampaignFilters(parsedQuery, accessToken);
  }
  if (
    parsedQuery?.search_text &&
    parsedQuery?.content_type === TABS.talks.label
  ) {
    return await GeminiProvider.getTalkFilters(parsedQuery, accessToken);
  }
  return undefined;
};

const maybeAddNoResults = (
  filteredContent: SearchFilteredContent,
  searchText: string
): SpacerData => {
  const shouldShowNoResults = !hasResults(filteredContent) && searchText;
  return {
    type: "Spacer",
    size: "large",
    content: shouldShowNoResults
      ? [SearchMapper.toEmptyResultsText()]
      : undefined,
  };
};

const hasResults = (filteredContent: SearchFilteredContent) => {
  if (Array.isArray(filteredContent)) {
    return filteredContent.some(
      (content) => (content?.pagination?.totalRecords || 0) > 0
    );
  }
  return (filteredContent?.pagination?.totalRecords || 0) > 0;
};

export const SearchService: Service = { retrievePageData };
